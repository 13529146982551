import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import { Container } from '@material-ui/core';

import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import Ribbon from '../../components/Ribbon';
import CardWide from '../../components/Cards/CardWide';
import Maze from '../../components/Maze';
import { verwarmingData } from '../../lib/overviewData';

export const query = graphql`
  query {
    mobileImage: file(relativePath: { eq: "verwarming/verwarming-6.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    desktopImage: file(relativePath: { eq: "verwarming/verwarming-6.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1300, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    gridImages1: file(relativePath: { eq: "verwarming/verwarming-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    gridImages2: file(relativePath: { eq: "verwarming/verwarming-5.png" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    gridImages3: file(relativePath: { eq: "verwarming/verwarming-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    gridImages4: file(relativePath: { eq: "verwarming/verwarming-3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

const Verwarming = ({ data, path, location }) => {
  const seo = {
    title: `E S I - alle verwarming`,
    description: `E S I biedt uw alle verwarming aan voor een warme oplossing.`,
    pathname: path,
    location,
  };

  const GridImages = [
    data.gridImages3,
    data.gridImages1,
    data.gridImages4,
    data.gridImages2,
  ];

  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: '(min-width: 970px)',
    },
  ];

  const ribbon = {
    image: sources,
    heading: `E S I - Verwarming`,
    pitchLine: `Van CV-ketel tot en met stadswarmte, van radiator tot en met vloerverwarming, E S I biedt een legio aan mogelijkheden.`,
  };

  const textTop = {
    header: `E S I - Installatietechniek B.V. kijkt samen met u naar de juiste warmte bron.`,
    textWithLink: () => (
      <>
        <p>
          Het lijkt zo vanzelfsprekend: een heerlijk warm huis als u thuiskomt
          en voldoende warm water tijdens het douchen. Hierdoor vergeten we al
          snel dat hier een hele installatie achter schuilgaat. Uw centrale
          verwarming bestaat vaak uit meerdere onderdelen: de cv-ketel,
          radiatoren, convectoren, vloerverwarming en een thermostaat die alles
          regelt. E S I - installatie helpt u bij het selecteren en installeren
          van deze producten.
        </p>
      </>
    ),
  };

  return (
    <Layout>
      <SEO
        title={seo.title}
        description={seo.description}
        location={location}
      />
      <Ribbon
        fluid={ribbon.image}
        heading={ribbon.heading}
        pitchLine={ribbon.pitchLine}
      />
      <Container fixed className='mt-10x mb-10x'>
        <CardWide
          className='center-xs'
          headerElem='h2'
          headerTitle={textTop.header}
          data-sal='slide-left'
        >
          <>
            {textTop.inner &&
              textTop.inner.map((text, i) => <p key={i}>{text}</p>)}
            {textTop.textWithLink && textTop.textWithLink()}
          </>
          <></>
        </CardWide>
        <Maze data={verwarmingData} path={path} gridImages={GridImages} />
      </Container>
    </Layout>
  );
};

Verwarming.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  path: PropTypes.string,
};

export default Verwarming;
